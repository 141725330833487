<template>
  <p class="align-center-copy">
    Copyright &copy;{{ new Date().getFullYear() }}
  </p>
</template>

<script>
export default {
  // eslint-disable-next-line no-undef, vue/multi-word-component-names
  name: "Footers",
};
</script>

<style scope>
p {
  color: white;
}
</style>